export default (options = {}) => ({
    _select2: null,
    init() {
        this._select2 = $(this.$el).select2({
            theme: 'bootstrap-5',
            language: 'it',
            width: '100%',
            ...options
        });

        this._select2.on('change', () => {
            this.$el._x_model.set(this._select2.val());
        });

        /*this._select2.on('select2:unselect', () => {
            this.$el._x_model.set(this._select2.val());
        });*/

        this._select2.on('select2:clear', () => this.clear());

        this.$watch(this.getModelledPropName(), (value) => {
            if (!value) {
                this.clear();
            }
        });
    },
    clear() {
        if (options.allowClear ?? false) {
            this._select2.val(null).trigger('change');
        }
    },
    getModelledPropName() {
        return this.$el.getAttribute('x-model');
    }
});
