import { listen } from 'quicklink';

import TimeChartStore from './store/timeChart.js';

import Select2 from './components/Select2.js';
import TimeChart from './components/TimeChart.js';

require('./bootstrap');

/**
 * @typedef {import('luxon').DateTime} DateTime
 */

$(document).ready(() => {
    Alpine.magic('dt', () => (date, format = null) => {
        switch (true) {
            case date instanceof DateTime:
                return date;
            case date instanceof Date:
                return DateTime.fromJSDate(date);
            case typeof date === 'string':
                if (!format) {
                    return DateTime.fromISO(date);
                }
        
                return DateTime.fromFormat(date, format);
        }
    });
    
    Alpine.store('chartOptions', {
        timeChart: TimeChartStore
    });
    
    Alpine.data('Select2', Select2);
    Alpine.data('TimeChart', TimeChart);

    Alpine.start();
});

document.addEventListener('livewire:load', () => {
    listen({
        priority: true,
        timeout: 2000,
        throttle: 4,
    });
});
