export default () => ({
    chart: null,
    get defaultOptions() {
        return this.$store?.chartOptions?.timeChart ?? {};
    },
    get data() {
        return this.chartData?.data;
    },
    get options() {
        return this.chartData?.options;
    },
    get xAxisBounds() {
        const labels = this.data?.labels;
        
        if (!labels) {
            return {};
        }
        
        return {
            min: labels[0] ?? null,
            max: labels[labels.length - 1] ?? null
        }
    },
    destroyChart() {
        this.chart?.destroy();
        this.chart = null;
    },
    renderChart() {
        if (this.chartData && !this.chart) {
            const setup = deepMerge(
                {
                    type: 'line',
                    options: {
                        parsing: true,
                        scales: {
                            x: this.xAxisBounds
                        },
                    },
                },
                { data: this.data },
                { options: this.defaultOptions },
                { options: this.options }
            );
            
            this.chart = new Chart(this.$el, setup);
        }
    },
    refreshChart() {
        this.destroyChart();
        this.renderChart();
    },
    init() {
        this.$nextTick(() => this.renderChart());
        
        this.$watch('chartData', () => {
            this.refreshChart();
        });
        
        this.$watch('period', () => {
            if (this.period !== 'custom') {
                this.$wire.emit('updateChartData');
            }
        });
    }
})
