/** @typedef {import('chart.js').Chart} Chart */

export default Object.freeze({
    locale: 'it-IT',
    scales: {
        x: {
            type: 'time',
        }
    },
    plugins: {
        zoom: {
            pan: {
                enabled: false,
                mode: 'x',
            },
            zoom: {
                wheel: {
                    enabled: false,
                },
                pinch: {
                    enabled: false
                },
                mode: 'x',
                onZoomComplete({ chart }) {
                    chart.update('none');
                }
            }
        },
    },
});
