import deepMerge from 'lodash.merge';
import { DateTime, Settings } from 'luxon';

import Alpine from 'alpinejs';
import jQuery from 'jquery';

import Chart from 'chart.js/auto';
import ZoomPlugin from 'chartjs-plugin-zoom';

import 'chartjs-adapter-luxon';

window.bootstrap = require('bootstrap');

window.deepMerge = deepMerge;

Settings.defaultLocale = 'it';
Settings.defaultZone = 'Europe/Rome';
window.DateTime = DateTime;

//Chart.register(ZoomPlugin);
window.Chart = Chart;

/**
 * We'll initialize jQuery because is needed by Select2.
 */

window.$ = window.jQuery = jQuery;

jQuery.ajaxSetup({
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

require('select2');
require('select2/dist/js/i18n/it.js');

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

/**
 * Init Alpine
 */
window.Alpine = Alpine;
